<template>
  <el-dialog width="1000px" :title="title" :visible.sync="visible" :close-on-click-modal="false"
    :close-on-press-escape="false" append-to-body>

    <el-form ref="insertForm" :model="form" label-position="right" label-width="130px" :rules="rules">
      <el-form-item label="任务名称" prop="taskName">
        <el-input v-model="form.taskName" placeholder="请输入..." clearable maxlength="200" />
      </el-form-item>
      <el-form-item label="资源类型" prop="sourceKey">
        <el-radio-group v-model="form.sourceKey" @change="sourceKeyChange()">
          <el-radio :label="t.sourceKey" v-for="(t, index) in sourceKeyList" :key="index">{{t.sourceName}}</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="任务描述" prop="taskDis">
        <el-input v-model="form.taskDis" placeholder="请输入..." clearable maxlength="200" />
      </el-form-item>
      <el-form-item label="资源子类型" prop="sourceSubKey">
        <el-checkbox-group v-model="form.sourceSubKey" @change="sourceSubKeyChange()">
          <el-checkbox :label="source.sourceKey" v-for="(source,index) in sourceSubKeyList" :key="index">{{source.sourceName}}</el-checkbox>
        </el-checkbox-group>
      </el-form-item>
      <el-form-item label="服务医生" prop="serviceDoctor">
        <el-input v-model="form.serviceDoctor" placeholder="请输入..." clearable maxlength="200" />
      </el-form-item>
      <el-form-item label="资源名称" prop="sourceId">
        <el-select v-model="form.sourceId" clearable placeholder="请选择" filterable>
          <el-option v-for="s in sourceList" :key="s.id" :label="s.sourceName" :value="s.id" />
        </el-select>
      </el-form-item>

      <template>
        <el-form-item label="发送时间" prop="delayNum">
          服务开启后第
          &nbsp;
          <el-input type="number" v-model="form.delayNum" :min="0" :max="servicePeriod-1" style="width: 5em;" :disabled="taskCycleDisabled" />
          &nbsp;
          天
        </el-form-item>

        <el-form-item label="任务引导" prop="taskGuidance">
          <el-input type="textarea" v-model="form.taskGuidance" style="height: 36px;" />
        </el-form-item>
        <el-form-item label="任务频次" prop="exeFrequency" style="flex: 0 0 100%;">
          <el-radio-group v-model="form.exeFrequency" @change="exeFrequencyChange()" :disabled="taskCycleDisabled">
            <el-radio-button label="1">单次</el-radio-button>
            <el-radio-button label="2">每天</el-radio-button>
            <el-radio-button label="3">每周</el-radio-button>
            <el-radio-button label="4">周期</el-radio-button>
          </el-radio-group>
          <br><br>
          <template v-if="form.exeFrequency == '4'">
            <el-card>
              <el-radio-group v-model="cycleDay" :disabled="taskCycleDisabled" @change="cycleDayChange">
                <el-radio-button :label="1">每天一次</el-radio-button>
                <el-radio-button :label="2">每天多次</el-radio-button>
              </el-radio-group>
              <br><br>
              每周期
              <el-input type="number" v-model="form.exePeriodDays" :min="0" style="width: 5em;" :max="servicePeriod - form.delayNum"
                :disabled="taskCycleDisabled" /> 天
              &nbsp; ，每天
              <el-input type="number" v-model="playCount" :min="0" style="width: 5em;" @change="updateCycleList"
                :disabled="cycleDay === 1 || taskCycleDisabled" /> 次
              &nbsp;
              <br><br>
              <div class="cycleTimeWrap">
                <div v-for="(cycleTime, index) in playCountTime" style="margin-bottom: 12px;" :key="index">
                  第{{index + 1}}次，
                  <el-time-picker value-format="timestamp" format="HH:mm" v-model="cycleTime.cTtime"
                    placeholder="请选择..." :disabled="taskCycleDisabled"></el-time-picker>
                </div>
              </div>
            </el-card>
          </template>
          <template v-else>
            <el-card>
              <template v-if="form.exeFrequency == '3'">
                <el-checkbox-group v-model="form.exeWeek">
                  <el-checkbox-button v-for="w in weekList" :label="w" :key="w"
                    :disabled="taskCycleDisabled">星期{{w}}</el-checkbox-button>
                </el-checkbox-group>
                <br>
              </template>
              执行时间 <el-time-picker value-format="timestamp" format="HH:mm" v-model="form.exeTime" placeholder="请选择..."
                :disabled="taskCycleDisabled"></el-time-picker>
            </el-card>
          </template>
        </el-form-item>
      </template>

    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="submit()">提 交</el-button>
      <el-button @click="visible = false">取 消</el-button>
    </span>
  </el-dialog>
</template>
<script>
  import request from '@/utils/request'
  import { getDict } from "@/api/common";


  export default {
    components: {
    },
    props: {
      servicePeriod: {
        type: Number,
        default: null
      }
    },
    data() {
      return {
        visible: false,
        sourceKeyList: [],
        sourceSubKeyList: [],
        sourceList: [],
        weekList: ['一', '二', '三', '四', '五', '六', '日'],
        title: '',
        rules: {
          taskName: [
            {
              required: true,
              message: "请输入任务名称",
              trigger: "blur",
            },
          ],
          sourceKey: [
            {
              required: true,
              message: "请输入资源类型",
              trigger: "change",
            },
          ],
          sourceSubKey: [
            {
              required: true,
              message: "请输入资源子类型",
              trigger: "change",
            },
          ],
          sourceId: [
            {
              required: true,
              message: "请输入资源名称",
              trigger: "change",
            },
          ],
        },
        cycleDay: 1,
        playCount: 1,
        playCountTime: [],
        form: {
          sourceId: '',
          sourceKey: '',
          sourceSubKey: [''],
          exeFrequency: 1,
          exeWeek: [],
          exePeriodDays: 1,
          exeTime: '',
        },
      };
    },
    computed: {
      taskCycleDisabled() {
        // select_goods 是 甄选商品 online_consultation 是 在线咨询
        return this.form.sourceSubKey && (this.form.sourceSubKey[0] == 'online_consultation' || this.form.sourceSubKey[0] == 'select_goods')
      },
    },
    watch: {
    },
    created() {
      // 资源类型树
      request({
        url: 'admin/kfSourceType/treeList',
        method: 'GET',
        showLoading: true
      }).then(({ res }) => {
        // console.log('sourceKeyList ---------> ', res);
        this.sourceKeyList = res;
      })
    },
    methods: {
      open(row = { delayNum: '', sourceId: '', sourceSubKey: '', exeFrequency: 1, exeWeek: '', exePeriodDays: 1, exeTime: '' }) {
        setTimeout(()=>{
          this.$refs.insertForm.clearValidate();
        }, 0)
        this.sourceList = [];
        this.form = { sourceKey: this.sourceKeyList[0].sourceKey, ...row };
        this.form.sourceSubKey = row.sourceSubKey ? [row.sourceSubKey] : [];
        this.sourceKeyChange(false);
        this.title = '编辑';
        if (!row.id) {
          this.title = '新增';
        } else {
          this.sourceSubKeyChange(false);
          this.form = { sourceKey: this.sourceKeyList[0].sourceKey, ...row };
          if (this.form.exeFrequency == '4') {
            this.playCountTime = this.form.exeTime.split(',').map(r => { return { cTtime: r } });
          } else {
            this.playCountTime = [{ cTtime: '' }];
          }
          this.form.sourceSubKey = [row.sourceSubKey];
          this.cycleDay = row.exePeriodFrequency;
          this.playCount = this.form.exeTime.split(',').length;
          this.updateCycleList(this.playCount);
        }
        this.form.exeWeek = row.exeWeek ? row.exeWeek.split(',') : [];
        this.visible = true;
      },
      // 任务频次 改变更新执行时间list
      exeFrequencyChange() {
        this.cycleDay = 1;
        this.playCount = 1;
        this.form.exeWeek = [];
        this.form.exeTime = '';
        this.form.exePeriodDays = 1;
        this.updateCycleList(this.playCount);
      },
      // 更新执行时间list
      updateCycleList(val) {
        if (!val) { return }
        const { length: currLength } = this.playCountTime;
        if (val > currLength) {
          let i = 0;
          while (i < val - currLength) {
            this.playCountTime.push({ time: '' })
            i++;
          }
        } else {
          this.playCountTime = this.playCountTime.filter((r, i) => i < val)
        }
      },
      // 资源父类型 改变
      sourceKeyChange(reset = true) {
        if (reset === true) {
          this.form.sourceSubKey = [];
          this.form.sourceId = "";
          this.sourceList = [];
          this.form.exeTime = '';
        }
        if (this.form.sourceKey) {
          const sourceRow = this.sourceKeyList.find(r => r.sourceKey == this.form.sourceKey);
          if (sourceRow) {
            this.sourceSubKeyList = sourceRow.children;
            return
          }
        }
        this.sourceSubKeyList = [];
      },
      // 资源子类型 改变 获取资源 下拉
      sourceSubKeyChange(reset = true) {
        this.form.sourceSubKey = this.form.sourceSubKey.slice(-1);
        if (reset === true) {
          this.form.sourceId = "";
          this.form.delayNum = "";
          this.form.exeTime = '';
          this.form.exeWeek = [];
        }
        this.cycleDay = 1;
        this.playCount = 1;
        this.form.exeFrequency = '1';
        this.form.exePeriodDays = 1;
        this.updateCycleList(this.playCount);
        this.sourceList = []
        if (this.form.sourceSubKey[0] === undefined) { return }
        request({
          url: 'admin/kfSource/getKfSourceInfo',
          params: {
            sourceSubKey: this.form.sourceSubKey[0],
          },
          method: 'GET',
          showLoading: true
        }).then(({ res }) => {
          console.log('sourceList ---------> ', res);
          this.sourceList = res;
        })
      },
      cycleDayChange(e) {
        if( e === 1) {
          //this.cycleDay = 1;
          this.playCount = 1;
          //this.form.exePeriodDays = 1;
          this.playCountTime = [{ cTtime: '' }];
        }
        
      },
      submit() {
        this.$refs.insertForm.validate((valid) => {
          if (valid) {
            // console.log('this.form ----------------->', this.form, this.playCountTime.map(r => r.cTtime));
            if (this.form.exeFrequency == '3' && this.form.exeWeek.length == 0) {
              this.$message.error('请选择星期');
              return
            }else if (this.form.exeFrequency == '4' && (this.playCountTime.map(r => r.cTtime).includes('') || this.playCountTime.map(r => r.cTtime).includes())) {
              this.$message.error('请填写执行时间')
              return
            } else if ( this.form.exeFrequency != '4' && !this.taskCycleDisabled && !this.form.exeTime) {
              this.$message.error('请填写执行时间')
              return
            }
            if (!this.taskCycleDisabled && !this.form.delayNum) {
              this.$message.error('请填写发送时间')
              return
            }

            let sourceKeyLabel;
            const param = JSON.parse(JSON.stringify(this.form));
            if (param.exeFrequency == '4') {
              param.exeTime = this.playCountTime.map(r => r.cTtime).join(',');
            }
            param.delayNum = parseInt(param.delayNum);
            param.exePeriodDays = parseInt(param.exePeriodDays);

            param.sourceSubKey = param.sourceSubKey[0];
            param.exeWeek = param.exeWeek && param.exeWeek != null && param.exeWeek.join ? param.exeWeek.join(',') : '';
            param.exePeriodFrequency = this.cycleDay;
            param.sourceKeyLabel = sourceKeyLabel;
            this.$emit('submit', param)
            // this.visible = false;
          }
        })
      },
    },
  };
</script>
<style scoped lang='scss'>
  ::v-deep {
    .el-form {
      display: flex;
      flex-wrap: wrap;
    }

    .el-form-item {
      flex: 0 0 50%;
    }

    .el-form-item__content {
      width: 70%;
    }

    .el-textarea__inner {
      max-height: 100px;
    }
  }
  .el-radio{
    margin-top: 8px;
  }

  .cycleTimeWrap {
    height: 150px;
    overflow: auto;
  }
</style>
