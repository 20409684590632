
<template>
    <el-dialog
        width="70%"
        :title="getTitle()"
        :visible.sync="visible"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :show-close="false"
        append-to-body
    >
        <el-form
            ref="insertForm"
            id="insertForm"
            :model="form"
            label-position="right"
            label-width="120px"
            class="formBox"
            :rules="rules"
        >
            <div class="mdrcc-info-title">基本信息</div>
            <el-row :gutter="10" type="flex" justify="start">
                <el-col :sm="12" :md="12" :lg="8" :xl="6">
                    <el-form-item label="名称" prop="name">
                        <el-input
                            v-model="form.name"
                            placeholder="请输入..."
                            clearable
                            :disabled = "disabled"
                            maxlength="50"
                        />
                    </el-form-item>
                </el-col>
                <el-col :sm="12" :md="12" :lg="8" :xl="6">
                    <el-form-item label="类型" prop="identifier">
                        <el-select
                            v-model="form.identifier"
                            clearable
                            :disabled = "disabled"
                            placeholder="请选择"
                            :filterable="true"
                        >
                            <el-option
                                v-for="option in identifierArray"
                                :key="option.value"
                                :label="option.label"
                                :value="option.value"
                            />
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :sm="12" :md="12" :lg="8" :xl="6">
                    <el-form-item label="肢体类型" prop="limbsType">
                        <el-select
                            v-model="form.limbsType"
                            clearable
                            :disabled = "disabled"
                            placeholder="请选择"
                            :filterable="true"
                        >
                            <el-option
                                v-for="option in limbsTypeArray"
                                :key="option.value"
                                :label="option.label"
                                :value="option.value"
                            />
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :sm="12" :md="12" :lg="8" :xl="6">
                    <el-form-item label="治疗类型" prop="treatmentType">
                        <el-select
                            v-model="form.treatmentType"
                            clearable
                            :disabled = "disabled"
                            placeholder="请选择"
                            :filterable="true"
                        >
                            <el-option
                                v-for="option in treatmentTypeArray"
                                :key="option.value"
                                :label="option.label"
                                :value="option.value"
                            />
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :sm="12" :md="12" :lg="8" :xl="6">
                    <el-form-item label="方案等级" prop="guidanceLevel">
                        <el-select
                            v-model="form.guidanceLevel"
                            clearable
                            :disabled = "disabled"
                            placeholder="请选择"
                            :filterable="true"
                        >
                            <el-option
                                v-for="option in guidanceLevelArray"
                                :key="option.value"
                                :label="option.label"
                                :value="option.value"
                            />
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :sm="24" :md="24" :lg="24" :xl="24">
                    <el-form-item label="描述" prop="describe">
                        <el-input
                            v-model="form.describe"
                            type="textarea"
                            clearable
                            :disabled = "disabled"
                            :autosize="{ minRows: 3, maxRows: 4 }"
                            maxlength="200"
                            placeholder="请输入内容"
                            show-word-limit
                        />
                    </el-form-item>
                </el-col>
                <el-col :sm="24" :md="24" :lg="24" :xl="24">
                    <el-descriptions class="margin-top" :title="`视频配置(共 ${getUploadVideoCount} 个)`" :column="4"></el-descriptions>
                        <el-row>
                            <el-button type="primary" @click="addVideoRow()" v-if="!disabled">添加</el-button>
                        </el-row>
                        <el-row :gutter="20" tag="ul" class="video-box">
                            <div v-for="(item,index) in form.videoInfoList" :key="index" class="video-box-item">
                                <el-col  :span="6">
                                    <el-row>
                                        <el-form-item label="分段标题" :prop="'videoInfoList.' + index + '.name'" :rules="rules.name">
                                           <el-input type="text" :disabled="disabled" v-if="!disabled || (item.name && item.name.length < 15)" maxlength="50" v-model="item.name" placeholder="请输入分段标题"/>
                                            <el-tooltip placement="top" v-else>
                                              <div slot="content" v-if="item.name">{{ item.name }}</div>
                                              <el-input type="text" :disabled="disabled" maxlength="200" v-model="item.name" placeholder="请输入分段标题"/>
                                            </el-tooltip>
                                        </el-form-item>
                                    </el-row>
                                    <el-row :span="6">
                                        <el-form-item label="分段视频" :prop="'videoInfoList.' + index + '.url'"
                                        :rules="rules.url">
                                        <el-popover
                                            placement="bottom"
                                            width="220"
                                            trigger="click">
                                            <el-button @click="setVideoClick(item, index)">本地视频</el-button>
                                            <el-button  @click="selectVideoClick(item, index)">从视频库选择</el-button>
                                            <div
                                                :src="item.url"
                                                v-show="!item.url"          
                                                class="defalutImg"
                                                style="margin: 8px 0px"
                                                v-loading="item.loading"
                                                slot="reference"
                                            >
                                                <i class="el-icon-plus"></i>
                                            </div>
                                            </el-popover>
                                                <video
                                                v-show="item.url"
                                                :src="commonConfig.$filePublicUrl + item.url"
                                                controls
                                                width="100%"
                                                height="150px;"
                                                style="margin: 0px auto"
                                            ></video>
                                            <input
                                                style="display: none"
                                                type="file"
                                                accept="*"
                                                @change="changeModelFile($event, item, index)"
                                                :ref="'ModelInput' + index"
                                            />
                                        </el-form-item>
                                    </el-row>
                                    <el-row :span="6">
                                        <el-form-item label="时长" :prop="'videoInfoList.' + index + '.length'"
                                        :rules="rules.length">
                                        <span>{{formatTimeData(item.length || 0)}}</span>
                                        </el-form-item>
                                    </el-row>
                                    <el-row :span="6">
                                        <el-form-item label="排序" :prop="'videoInfoList.' + index + '.sort'"
                                        :rules="rules.sort">
                                            <el-input-number :disabled="disabled" controls-position="right" :min="1" :max="100" step-strictly
                                            v-model="item.sort" placeholder="排序" class="width120"/>
                                        </el-form-item>
                                    </el-row>
                                    <el-row :span="6" style="min-width: 95px;" v-if="!disabled">
                                        <el-form-item label="">
                                            <el-button type="warning" :disabled="disabled" icon="el-icon-plus"
                                            @click="addVideoRow"
                                            circle></el-button>
                                            <el-button type="danger" :disabled="disabled" icon="el-icon-delete" @click="delVideoRow(index)"
                                                    circle></el-button>
                                        </el-form-item>
                                    </el-row>
                                </el-col>

                            </div>
                        </el-row>
                    </el-form-item>
                </el-col>
            </el-row>
            <div class="mdrcc-info-title">内容配置</div>
            <el-row :gutter="10" type="flex" justify="start">
                <el-col :sm="24" :md="24" :lg="24" :xl="24">
                    <el-form-item prop="trainType">
                        <template slot="label">
                            训练类型
                        </template>
                            <el-tag
                                :key="index"
                                v-for="(tag,index)  in form.trainList"
                                size="small"
                                :closable="!disabled"
                                :disable-transitions="false"
                                :show-message="false"
                                @close="handleClose(tag, 'train')">
                                {{tag}}
                                </el-tag>
                                <el-input
                                class="input-new-tag"
                                v-if="trainInputVisible"
                                v-model="trainInputValue"
                                ref="trainSaveInput"
                                size="small"
                                maxlength="10"
                                @keyup.enter.native="handleInputConfirm('train')"
                                @blur="handleInputConfirm('train')"
                                >
                                </el-input>
                                <el-button class="button-new-tag" size="small" @click="showInput('train')" v-if="form.trainList.length <10 && !disabled">+ 训练类型</el-button>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="10" type="flex" justify="start">
                <el-col :sm="24" :md="24" :lg="24" :xl="24">
                    <el-form-item prop="intendedPopulation">
                        <template slot="label">
                            适用人群
                        </template>
                        <el-tag
                            :key="index"
                            v-for="(tag,index) in form.targetList"
                            :closable="!disabled"
                            :disable-transitions="false"
                            @close="handleClose(tag, 'target')">
                            {{tag}}
                            </el-tag>
                            <el-input
                            class="input-new-tag"
                            v-if="targetInputVisible"
                            v-model="targetInputValue"
                            ref="targetSaveInput"
                            maxlength="10"
                            size="small"
                            @keyup.enter.native="handleInputConfirm('target')"
                            @blur="handleInputConfirm('target')"
                            >
                            </el-input>
                            <el-button class="button-new-tag" size="small" @click="showInput('target')" v-if="form.targetList.length <10 && !disabled">+ 适用人群</el-button>
                    </el-form-item>
                </el-col>
            </el-row>
        <el-row :gutter="10" type="flex" justify="start">
            <el-col :sm="24" :md="24" :lg="24" :xl="24">
                <el-form-item prop="tabooIntendedPopulation">
                    <template slot="label">
                            禁忌人群
                        </template>
                    <el-tag
                        :key="index"
                        v-for="(tag,index) in form.taboosList"
                        :closable="!disabled"
                        :disable-transitions="false"
                        @close="handleClose(tag, 'taboos')">
                        {{tag}}
                        </el-tag>
                        <el-input
                        class="input-new-tag"
                        v-if="taboosInputVisible"
                        v-model="taboosInputValue"
                        ref="taboosSaveInput"
                        size="small"
                        maxlength="10"
                        @keyup.enter.native="handleInputConfirm('taboos')"
                        @blur="handleInputConfirm('taboos')"
                        >
                        </el-input>
                        <el-button class="button-new-tag" size="small" @click="showInput('taboos')" v-if="form.taboosList.length <10 && !disabled">+ 禁忌人群</el-button>
                </el-form-item>
            </el-col>
        </el-row>
        <el-row :gutter="10" type="flex" justify="start">
            <el-col :sm="24" :md="24" :lg="24" :xl="24">
                <el-form-item label="注意事项" prop="notes">
                    <el-input
                        v-model="form.notes"
                        type="textarea"
                        clearable
                        :disabled = "disabled"
                        :autosize="{ minRows: 3, maxRows: 4 }"
                        maxlength="200"
                        show-word-limit
                        placeholder="请输入内容"
                    />
                </el-form-item>
            </el-col>
        </el-row>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="submit()" v-if="!disabled">提 交</el-button>
            <el-button @click="updateVisible(false)">取 消</el-button>
        </span>
        <commonVideoSelectDialog
        ref="videoList"
        :id="selectVideoId"
        :index="selectVideoIndex"
        selectMode="single"
        @submitVideo="selectedVideo"
        />
    </el-dialog>
</template>
<script>
import commonVideoSelectDialog from '../components/commonVideoSelectDialog'
import { insertData, getDetail, editData } from "@/api/segmentedVideo";
import { uploadPublicFile , getPublicVideoInfo} from "@/utils/uploadQiniu.js";
import { formatTime } from "@/utils/common";
import moment from 'moment'
export default {
    props: {
        id: {
            type: String,
        },
        timeStamp: {
            type: String,
        },
        identifierArray: {
            required: true,
            type: Array,
        },
        limbsTypeArray: {
            required: true,
            type: Array,
        },
        treatmentTypeArray: {
            required: true,
            type: Array,
        },
        guidanceLevelArray: {
            required: true,
            type: Array,
        },
    },
    components: {
      commonVideoSelectDialog
    },
    data() {
        return {
            videoLength: '',
            checkId: '',
            selectVideoId: '',
            selectVideoIndex: 0,
            rules: {
                name: [
                    {
                        required: true,
                        message: "请输入名称",
                        trigger: "blur",
                    },
                ],
                identifier: [
                    {
                        required: true,
                        message: "请选择类型",
                        trigger: "change",
                    },
                ],
                describe: [
                    {
                        required: true,
                        message: "请输入描述",
                        trigger: "blur",
                    },
                ],
                name: [
                    {
                        required: true,
                        message: "请输入分段标题",
                        trigger: "blur",
                    },
                ],
                url: [
                    {
                        required: true,
                        message: "请上传分段视频",
                        trigger: "change",
                    },
                ],
                sort: [
                    {
                        required: true,
                        message: "请设置分段视频排序",
                        trigger: "blur",
                    },
                ],
                trainType: [
                    {
                        required: true,
                        message: "请设置训练类型",
                    },
                ],
                intendedPopulation: [
                    {
                        required: true,
                        message: "请设置适用人群",
                        trigger: "",

                    },
                ],
                tabooIntendedPopulation: [
                    {
                        required: true,
                        message: "请设置禁忌人群",
                        trigger: "",
                    },
                ],
                notes: [
                    {
                        required: true,
                        message: "请输入注意事项",
                        trigger: "",
                    },
                ],
            },
            form: {
                id: '',
                name: "", //名称
                identifier: "", //
                limbsType: "", //肢体类型
                treatmentType: "", //治疗类型
                guidanceLevel: "", //方案等级
                number: "", //次数（每天几次)
                length: "", //时长（一次多长分钟）
                lengthExercise: "", //锻炼时长（锻炼几天）
                sort: "", //排序
                describe: "", //描述
                photo: "", //封面图片
                url: "", //视频或者设备地址
                logoFile: "",
                kfGuidanceTypeId: "", //分类ID
                videoLength: "", // 视频时长
                videoInfoList: [{
                    id: '',
                    videoId: '',
                    url: '',
                    name : '',
                    sort: ''
                }],
                trainType:'',
                intendedPopulation:'',
                tabooIntendedPopulation:'',
                trainList: [],  //训练类型
                targetList: [], //适用人群
                taboosList: [], //禁忌人群

            },
            disabled: false,
            visible: false,
            loading: false,
            loadingPhoto: false,
            dialogVisible: false,
            disabled: false,
            trainInputVisible: false,
            trainInputValue: '',
            targetInputVisible: false,
            targetInputValue: '',
            taboosInputVisible: false,
            taboosInputValue: '',
        };
    },
    watch: {
        visible(newVal, oldVal) {
            if (newVal === true) {
                if (this.id) {
                    this.getDetail();
                }
                //...todo
            }
        },
    },
    computed:{
        getUploadVideoCount() {
            let lst =  this.form.videoInfoList.filter(item => {
                return item.url;
            });
            return lst.length;
        }
    },
    created() {},
    mounted() {},
    methods: {
        formatTimeData(val) {
          return formatTime(val);
        },
        toBottom(){
          // 滚动到弹窗底部
          this.$nextTick(() => {
            const container = this.$el.querySelector('.formBox');
            if (container) {
              container.scrollIntoView(false);
            }
          });
        },
        submit() {
            this.$refs.insertForm.validate((valid) => {
                if (valid) {
                    if (this.form.videoInfoList.length === 0) {
                        this.$message({
                            message: '请最少上传一个视频',
                            type: 'error'
                        })
                        return;
                    }
                    const parm = Object.assign(this.form);
                    parm.intendedPopulation = parm.targetList.join(',');
                    parm.tabooIntendedPopulation = parm.taboosList.join(',');
                    parm.trainType = parm.trainList.join(',');
                    if (this.hasSameSortNo()) {
                        this.$message({
                            message: '有相同的排序号存在！',
                            type: 'error'
                        })
                        return;
                    }
                    if (this.id === "") {
                        insertData(parm).then(({ res }) => {
                            this.submitSuccess(res);
                        });
                    } else {
                        editData(parm, this.timeStamp).then(({ res }) => {
                            this.submitSuccess(res);
                        });
                    }
                } else {
                    if (this.form.videoInfoList.length === 0) {
                        this.$message({
                            message: '请最少上传一个视频',
                            type: 'error'
                        })
                        return;
                    }
                }
            });
        },
        getTitle(){
            if (this.disabled) {
                return "康复指导视频设备详情";
            }
           return this.id === '' ? '新增康复指导视频设备' : '修改康复指导视频设备';
        },
        hasSameSortNo(){
            let obj = {};
            let videoInfoList = this.form.videoInfoList.reduce((cur, next) => {
                obj[next.sort] ? '' : (obj[next.sort] = true && cur.push(next));
                return cur;
            }, []);
            console.log('videoInfoList====',videoInfoList);
           return videoInfoList.length != this.form.videoInfoList.length;
        },
        showHideLoading(state,index){
            let fileInfo = this.form.videoInfoList[index];
            if (fileInfo) {
              fileInfo.loading = true;
              this.$set(this.form.videoInfoList, index, fileInfo)
            }
        },
        videoLengthChange(e){
            this.setFormVideoLength(e);
            this.$refs.insertForm.validateField('videoLength');
        },
        submitSuccess(res) {
            this.$message({ ...this.commonConfig.$handleSuccess });
            this.updateVisible(false);
            this.$emit("submit");
        },
        getDetail() {
            getDetail({ id: this.id }).then(({ res, timeStamp }) => {
                this.form = res;
                this.form.trainList = res.trainType.split(',');  //训练类型
                this.form.targetList = res.intendedPopulation.split(','); //适用人群
                this.form.taboosList = res.tabooIntendedPopulation.split(',');  //禁忌人群
            });
        },
        setLogoClick() {
            this.$refs.logoInput.click();
        },

        setVideoClick(item, index) {
            this.$refs['ModelInput'+index][0].click();
        },
        selectVideoClick(item, index){
            this.selectVideoId = item.id
            this.selectVideoIndex = index
            this.$refs.videoList.open();
        },
        selectedVideo(data){
            console.log('selectedVideo---', data)
            this.form.videoInfoList[data.index].url = data.row.videoUrl
            this.selectVideoId = ''
            this.selectVideoIndex = 0
            const thiz = this;
            getPublicVideoInfo(data.row.videoUrl).then(res=>{
                if (res && res.streams.length) {
                // 获取视频时长和宽、高信息
                const videoH264Info = res.streams.filter(
                    (item) => item.codec_name === 'h264'
                );

                if (videoH264Info.length) {
                    let item =  thiz.form.videoInfoList[data.index];
                    item.length = Math.floor(videoH264Info[0].duration);
                    item.width = videoH264Info[0].width;
                    item.height = videoH264Info[0].height;
                    this.$set(this.form.videoInfoList, data.index, item)
                    console.log('thiz.form.videoInfoList====',thiz.form.videoInfoList);
                }
                }
            })
        },
        setFormVideoLength(value){
            const hour = moment(value).hour();
            const minute = moment(value).minute();
            const second = moment(value).second();
            this.form.videoLength = hour * 60 * 60 + minute * 60 + second;
            if(!this.form.videoLength){
                this.$message({
                message: '时长不能设置为0秒',
                type: 'error'
              })
            }
            console.log('form videoLength---', this.form.videoLength)
        },
        /**
         * @description: 上传头像
         * @param {*} event
         * @return {*}
         */
        changeLogoImage(event) {
            this.loadingPhoto = true;
            let file = event.target.files[0];
            const thiz = this;
            uploadPublicFile(file)
                .then((success) => {
                    console.log("success====", success);
                    thiz.loadingPhoto = false;
                    thiz.form.photo = success;
                })
                .catch((handleError) => {
                    thiz.loadingPhoto = false;
                    this.$message({
                        message: "上传失败，请重试",
                        type: "warning",
                    });
                    console.log("handleError====", handleError);
                });
        },
        /**
         * @description: 上传视频
         */
        changeModelFile(event, item, index) {
            this.showHideLoading(true,index);
            let file = event.target.files[0];
            const thiz = this;
            uploadPublicFile(file)
                .then((success) => {
                    console.log("success====", success);
                    thiz.showHideLoading(false,index);
                    thiz.form.videoInfoList[index].url = success;
                    getPublicVideoInfo(success).then(res=>{
                      console.log('res=====',res)
                      if (res && res.streams.length) {
                        // 获取视频时长和宽、高信息
                        const videoH264Info = res.streams.filter(
                            (item) => item.codec_name === 'h264'
                        );

                        if (videoH264Info.length) {
                          let item =  thiz.form.videoInfoList[index];
                          item.length = Math.floor(videoH264Info[0].duration);
                          item.width = videoH264Info[0].width;
                          item.height = videoH264Info[0].height;
                          this.$set(this.form.videoInfoList, index, item)
                          console.log('thiz.form.videoInfoList====',thiz.form.videoInfoList);
                        }
                      }
                    })
                })
                .catch((handleError) => {
                    thiz.showHideLoading(false,index);
                    this.$message({
                        message: "上传失败，请重试",
                        type: "warning",
                    });
                    console.log("handleError====", handleError);
                });
        },
        updateVisible(val,disabled = false) {
            this.disabled = disabled
            this.visible = val;
            if (val === false) {
                this.$refs.insertForm.resetFields();
                this.form.id = '';
                this.form = {
                    id: '',
                    name: "", //名称
                    identifier: "", //
                    limbsType: "", //肢体类型
                    treatmentType: "", //治疗类型
                    guidanceLevel: "", //方案等级
                    number: "", //次数（每天几次)
                    length: "", //时长（一次多长分钟）
                    lengthExercise: "", //锻炼时长（锻炼几天）
                    sort: "", //排序
                    describe: "", //描述
                    photo: "", //封面图片
                    url: "", //视频或者设备地址
                    logoFile: "",
                    kfGuidanceTypeId: "", //分类ID
                    videoInfoList: [{
                        id: '',
                        videoId: '',
                        url: '',
                        title : '',
                        sort: ''
                    }],
                    trainType:'',
                    intendedPopulation:'',
                    tabooIntendedPopulation:'',
                    trainList: [],  //训练类型
                    targetList: [], //适用人群
                    taboosList: [], //禁忌人群
                }
            }
        },
        removePhoto() {
            this.form.photo = "";
        },
        removeVideo(index) {
            this.form.videoInfoList[index].url = "";
        },

        addVideoRow: function () {
            let videoId = ''
            if (this.form) {
                videoId = this.form.id
            }
            this.form.videoInfoList.push({
                'id': '',
                'videoId': videoId,
                'url': '',
                'title' : '',
                'sort': ''
            })
            this.toBottom();
        },
        delVideoRow: function (index) {
            // 最后一行时禁止删除
            // if (this.form.videoInfoList.length === 1) {
            //     return
            // }
            this.form.videoInfoList.splice(index, 1)
        },
        handleClose(tag, type) {
            this.form[type + 'List'].splice(this.form[type + 'List'].indexOf(tag), 1)
            if (type == 'train') {
                // 更新表单训练类型字段
                this.form.trainType = this.form[type + 'List'].join(',')
                this.$forceUpdate();
                return;
            }
            if (type == 'target') {
                // 更新表单适用人群字段
                this.form.intendedPopulation = this.form[type + 'List'].join(',')
                this.$forceUpdate();
                return;
            }
            if (type == 'taboos') {
               // 更新表单禁忌人群字段
                this.form.tabooIntendedPopulation = this.form[type + 'List'].join(',')
                this.$forceUpdate();
                return;
            }
        },

        showInput(type) {
            this[type + 'InputVisible'] = true
            this.$nextTick(_ => {
            this.$refs[type + 'SaveInput'].$refs.input.focus()
            });
        },

        handleInputConfirm(type) {
            let inputValue =  this[type + 'InputValue'].trim()
            if (inputValue) {
              if (this.form[type + 'List'].indexOf(inputValue) > -1) {
                this.$message.error("输入内容已存在！");
                return
              }
              this.form[type + 'List'].push(inputValue)
              if (type == 'train') {
                this.form.trainType = this.form[type + 'List'].join(',')
              }
              if (type == 'target') {
                this.form.intendedPopulation = this.form[type + 'List'].join(',')
              }
              if (type == 'taboos') {
                this.form.tabooIntendedPopulation = this.form[type + 'List'].join(',')
              }
              console.log('this.form',this.form);
              this[type + 'InputVisible'] = false
              this[type + 'InputValue'] = ''
            }
        }
    }
};
</script>
<style scoped lang='scss'>
@import "~@/styles/variables.scss";
.img{
    position: relative;
    width: 150px;
    height: 150px;
}
.imgBox {
    width: 150px;
    height: 150px;
    object-fit: contain;
    border: 1px solid $tableColor;
}
.remove {
    position: absolute;
    right: -0.5em;
    top: -0.5em;
    line-height: 1;
    font-size: 16px;
    color: #aaaaaa;
    // background-color: #ffffff;
    cursor: pointer;
    z-index: 10;
}
.defalutImg {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 150px;
    height: 150px;
    border: 1px solid $tableColor;
    border-radius: 4px;
    cursor: pointer;
}

.video-upload-img {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    border: 1px solid $tableColor;
    border-radius: 4px;
    cursor: pointer;
}
.el-form-item--mini.el-form-item {
  margin-bottom: 15px;
}

.video-box{
  min-height: 356px;
}

.el-tag + .el-tag {
    margin-left: 10px;
  }
  .button-new-tag {
    margin-left: 10px;
    height: 24px;
    line-height: 24px;
    padding-top: 0;
    border-radius: 4px;
    padding-bottom: 0;
  }
  .input-new-tag {
    width: 90px;
    margin-left: 10px;
    vertical-align: bottom;
  }

  .required{
    color: #e44d42;
    margin-right: 4px;
  }
</style>

