
<template>
    <el-dialog
        width="70%"
        :title="id === '' ? '新增康复指导视频设备' : '修改康复指导视频设备'"
        :visible.sync="visible"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :show-close="false"
        append-to-body
    >
        <el-form
            ref="insertForm"
            :model="form"
            label-position="right"
            label-width="120px"
            class="formBox"
            :rules="rules"
        >
            <div class="mdrcc-info-title">基本信息</div>
            <el-row :gutter="10" type="flex" justify="start">
                <el-col :sm="12" :md="12" :lg="8" :xl="6">
                    <el-form-item label="名称" prop="name">
                        <el-input
                            v-model="form.name"
                            placeholder="请输入..."
                            clearable
                            maxlength="200"
                        />
                    </el-form-item>
                </el-col>
                <el-col :sm="12" :md="12" :lg="8" :xl="6">
                    <el-form-item label="类型" prop="identifier">
                        <el-select
                            v-model="form.identifier"
                            clearable
                            placeholder="请选择"
                            :filterable="true"
                        >
                            <el-option
                                v-for="option in identifierArray"
                                :key="option.value"
                                :label="option.label"
                                :value="option.value"
                            />
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :sm="12" :md="12" :lg="8" :xl="6">
                    <el-form-item label="肢体类型" prop="limbsType">
                        <el-select
                            v-model="form.limbsType"
                            clearable
                            placeholder="请选择"
                            :filterable="true"
                        >
                            <el-option
                                v-for="option in limbsTypeArray"
                                :key="option.value"
                                :label="option.label"
                                :value="option.value"
                            />
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :sm="12" :md="12" :lg="8" :xl="6">
                    <el-form-item label="治疗类型" prop="treatmentType">
                        <el-select
                            v-model="form.treatmentType"
                            clearable
                            placeholder="请选择"
                            :filterable="true"
                        >
                            <el-option
                                v-for="option in treatmentTypeArray"
                                :key="option.value"
                                :label="option.label"
                                :value="option.value"
                            />
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :sm="12" :md="12" :lg="8" :xl="6">
                    <el-form-item label="方案等级" prop="guidanceLevel">
                        <el-select
                            v-model="form.guidanceLevel"
                            clearable
                            placeholder="请选择"
                            :filterable="true"
                        >
                            <el-option
                                v-for="option in guidanceLevelArray"
                                :key="option.value"
                                :label="option.label"
                                :value="option.value"
                            />
                        </el-select>
                    </el-form-item>
                </el-col>
                <!-- <el-col :sm="12" :md="12" :lg="8" :xl="6">
                    <el-form-item label="分类" prop="kfGuidanceTypeId">
                        <el-cascader
                            v-model="form.kfGuidanceTypeId"
                            :options="classifyArray"
                            :props="{
                                checkStrictly: true,
                                emitPath: false,
                                label: 'officesName',
                                value: 'id',
                            }"
                            clearable
                            filterable
                        />
                    </el-form-item>
                </el-col> -->
                <el-col :sm="12" :md="12" :lg="8" :xl="6">
                    <el-form-item label="排序" prop="sort">
                        <el-input
                            v-model="form.sort"
                            placeholder="请输入..."
                            clearable
                            maxlength="20"
                            onkeyup="this.value=this.value.replace(/[^\d.]/g,'')"
                            oninput="this.value=this.value.replace(/[^\d.]/g,'')" 
                            @change="(text) => {this.form.sort = text}"
                        />
                    </el-form-item>
                </el-col>
                
                <el-col :sm="12" :md="12" :lg="8" :xl="12">
                    <el-form-item label="封面图片" prop="photo">
                        <div class="img">
                            <div v-if="form.photo" class="remove" @click="removePhoto()"><i class="el-icon-error"></i></div>
                            <img
                                :src="commonConfig.$filePublicUrl + form.photo"
                                v-show="form.photo"
                                @click="setLogoClick"
                                class="imgBox"
                            />
                            <div
                                :src="form.photo"
                                v-show="!form.photo"
                                @click="setLogoClick"
                                class="defalutImg"
                                v-loading="loadingPhoto"
                            >
                                <i class="el-icon-plus"></i>
                            </div>
                        </div>
                        <input
                            type="file"
                            name="form.photo"
                            accept="image/gif,image/jpeg,image/jpg,image/png"
                            style="display: none"
                            @change="changeLogoImage($event)"
                            ref="logoInput"
                        />
                    </el-form-item>
                </el-col>
                <el-col :sm="12" :md="12" :lg="8" :xl="12">
                    <el-form-item label="视频或者设备地址" prop="url">
                        <div v-if="form.url" class="remove" @click="removeVideo()"><i class="el-icon-error"></i></div>
                      
                          <div class="video">
                            <video
                              v-show="form.url"
                              :src="commonConfig.$filePublicUrl + form.url"
                              controls
                              width="100%"
                              height="150px"
                            ></video>
                            <el-popover
                              placement="bottom"
                              width="220"
                              trigger="click">
                              <el-button  @click="setVideoClick">本地视频</el-button>
                              <el-button  @click="selectVideoClick">从视频库选择</el-button>
                            <div
                              :src="form.url"
                              v-show="!form.url"                         
                              class="defalutImg"
                              v-loading="loading"
                              slot="reference"
                            >
                              <i class="el-icon-plus"></i>
                            </div>
                          </el-popover>   
                        </div>
                                       
                      <input
                        style="display: none"
                        type="file"
                        accept="*"
                        @change="changeModelFile($event)"
                        ref="ModelInput"
                      />
                    </el-form-item>
                </el-col>
                <el-col :sm="12" :md="12" :lg="8" :xl="6">
                    <el-form-item label="次数（每天几次)" prop="number">
                        <el-input-number
                            v-model="form.number"
                            name="number"
                            :min="1"
                            :max="99"
                            label="次数（每天几次)"
                        />
                    </el-form-item>
                </el-col>
                <el-col :sm="12" :md="12" :lg="8" :xl="6">
                    <el-form-item label="时长（一次多长分钟）" prop="length">
                        <el-input-number
                            v-model="form.length"
                            name="number"
                            :min="1"
                            :max="99"
                            label="时长（一次多长分钟）"
                        />
                    </el-form-item>
                </el-col>
                <el-col :sm="12" :md="12" :lg="8" :xl="6">
                    <el-form-item
                        label="锻炼时长（锻炼几天）"
                        prop="lengthExercise"
                    >
                        <el-input-number
                            v-model="form.lengthExercise"
                            name="number"
                            :min="1"
                            :max="99"
                            label="计数器"
                        />
                    </el-form-item>
                </el-col> 
                <el-col :sm="12" :md="12" :lg="8" :xl="6">
                    <el-form-item
                        label="视频时长"
                        prop="videoLength"
                    >
                    <el-time-picker
                        v-model="videoLength"
                        :picker-options="{
                        selectableRange: '00:00:00 - 23:59:59'
                        }"
                        @change="videoLengthChange"
                        placeholder="视频时长">
                    </el-time-picker>
                    </el-form-item>
                </el-col>
                <el-col :sm="24" :md="24" :lg="24" :xl="24">
                    <el-form-item label="描述" prop="describe">
                        <el-input
                            v-model="form.describe"
                            type="textarea"
                            clearable
                            :autosize="{ minRows: 2, maxRows: 4 }"
                            maxlength="200"
                            placeholder="请输入内容"
                        />
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
        <commonVideoSelectDialog
        ref="videoList"
        selectMode="single"
        @submitVideo="selectedVideo"
        />
        <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="submit()">提 交</el-button>
            <el-button @click="updateVisible(false)">取 消</el-button>
        </span>
    </el-dialog>
</template>
<script>
import commonVideoSelectDialog from '../components/commonVideoSelectDialog'
import { insertData, editData, getDetail } from "@/api/videoEquipment";
import { uploadPrivateFile, uploadPublicFile } from "@/utils/uploadQiniu.js";
import moment from 'moment'
export default {
    props: {
        id: {
            type: String,
        },
        timeStamp: {
            type: String,
        },
        identifierArray: {
            required: true,
            type: Array,
        },
        limbsTypeArray: {
            required: true,
            type: Array,
        },
        treatmentTypeArray: {
            required: true,
            type: Array,
        },
        guidanceLevelArray: {
            required: true,
            type: Array,
        },
        // classifyArray: {
        //     required: true,
        //     type: Array,
        // },
    },
    components: {
      commonVideoSelectDialog
    },
    data() {
        return {
            videoLength: '',
            checkId: "",
            rules: {
                name: [
                    {
                        required: true,
                        message: "请输入名称",
                        trigger: "blur",
                    },
                ],
                identifier: [
                    {
                        required: true,
                        message: "请选择类型",
                        trigger: "change",
                    },
                ],
                videoLength: [
                    {
                        required: false,
                        message: "请设置视频时长",
                        trigger: "change",
                    },
                ],
                
                // kfGuidanceTypeId: [
                //     {
                //         required: true,
                //         message: "请选择分类",
                //         trigger: "change",
                //     },
                // ],
                describe: [
                    {
                        required: true,
                        message: "请输入描述",
                        trigger: "blur",
                    },
                ],
            },
            form: {
                id: '', 
                name: "", //名称
                identifier: "", //
                limbsType: "", //肢体类型
                treatmentType: "", //治疗类型
                guidanceLevel: "", //方案等级
                number: "", //次数（每天几次)
                length: "", //时长（一次多长分钟）
                lengthExercise: "", //锻炼时长（锻炼几天）
                sort: "", //排序
                describe: "", //描述
                photo: "", //封面图片
                url: "", //视频或者设备地址
                logoFile: "",
                kfGuidanceTypeId: "", //分类ID
                videoLength: "", // 视频时长
            },
            visible: false,
            loading:false,
            loadingPhoto:false,
            // dialogImageUrl: "",
            dialogVisible: false,
        };
    },
    watch: {
        visible(newVal, oldVal) {
            if (newVal === true) {
                if (this.id) {
                    this.getDetail();
                }
                //...todo
            }
        },
    },
    created() {},
    mounted() {},
    methods: {
        submit() {
            this.$refs.insertForm.validate((valid) => {
                if (valid) {
                    const parm = this.form;
                    if(this.videoLength){
                        this.setFormVideoLength(this.videoLength);
                    }
                    if (this.id === "") {
                        insertData(parm).then(({ res }) => {
                            this.submitSuccess(res);
                        });
                    } else {
                        editData(parm, this.timeStamp).then(({ res }) => {
                            this.submitSuccess(res);
                        });
                    }
                }
            });
        },
        videoLengthChange(e){
            this.setFormVideoLength(e);
            this.$refs.insertForm.validateField('videoLength');
        },
        submitSuccess(res) {
            this.$message({ ...this.commonConfig.$handleSuccess });
            this.updateVisible(false);
            this.$emit("submit");
        },
        getDetail() {
            getDetail({ id: this.id }).then(({ res, timeStamp }) => {
                this.form = res;
                this.initVideoLength(this.form.videoLength);
            });
        },
        setLogoClick() {
            this.$refs.logoInput.click();
        },

        setVideoClick() {
            console.log('setVideoClick')
            this.$refs.ModelInput.click();
        },
        selectVideoClick(){
            this.$refs.videoList.open();
        },
        selectedVideo(data){
            console.log('selectedVideo---', data.row)
            this.form.url = data.row.videoUrl;
        },
        setFormVideoLength(value){
            const hour = moment(value).hour();
            const minute = moment(value).minute();
            const second = moment(value).second();
            this.form.videoLength = hour * 60 * 60 + minute * 60 + second;
            if(!this.form.videoLength){
                this.$message({
                message: '时长不能设置为0秒',
                type: 'error'
              })
            }
            console.log('form videoLength---', this.form.videoLength)
        },
        initVideoLength(value){
            var secondTime = parseInt(value);// 秒
            var minuteTime = 0;// 分
            var hourTime = 0;// 小时
            if(secondTime > 60) {//如果秒数大于60，将秒数转换成整数
                //获取分钟，除以60取整数，得到整数分钟
                minuteTime = parseInt(secondTime / 60);
                //获取秒数，秒数取余，得到整数秒数
                secondTime = parseInt(secondTime % 60);
                //如果分钟大于60，将分钟转换成小时
                if(minuteTime > 60) {
                    //获取小时，获取分钟除以60，得到整数小时
                    hourTime = parseInt(minuteTime / 60);
                    //获取小时后取余的分，获取分钟除以60取余的分
                    minuteTime = parseInt(minuteTime % 60);
                }
            }
            var result = "" + parseInt(secondTime);

            if(minuteTime > 0) {
                result = "" + parseInt(minuteTime) + ":" + result;
            }else{
                result = "" + "00" + ":" + result;
            }
            if(hourTime > 0) {
            result = "" + parseInt(hourTime) + ":" + result;
            }else{
                result = "" + "00" + ":" + result;
            }
            console.log('result',result);
            
            const momentObj = moment(result, 'HH:mm:ss'); 
            this.videoLength = momentObj;
        },
        /**
         * @description: 上传头像
         * @param {*} event
         * @return {*}
         */
        changeLogoImage(event) {
            this.loadingPhoto = true;
            let file = event.target.files[0];
            const thiz = this;
            uploadPublicFile(file)
                .then((success) => {
                    console.log("success====", success);
                    thiz.loadingPhoto = false;
                    thiz.form.photo = success;
                })
                .catch((handleError) => {
                    thiz.loadingPhoto = false;
                    this.$message({
                        message: "上传失败，请重试",
                        type: "warning",
                    });
                    console.log("handleError====", handleError);
                });
        },
        /**
         * @description: 上传视频
         */
        changeModelFile(event) {
            this.loading = true;
            let file = event.target.files[0];
            const thiz = this;
            uploadPublicFile(file)
                .then((success) => {
                    console.log("success====", success);
                    thiz.loading = false;
                    thiz.form.url = success;
                })
                .catch((handleError) => {
                    thiz.loading = false;
                    this.$message({
                        message: "上传失败，请重试",
                        type: "warning",
                    });
                    console.log("handleError====", handleError);
                });
        },
        updateVisible(val) {
            this.visible = val;
            if (val === false) {
                this.$refs.insertForm.resetFields();
                this.form.id = '';
                this.videoLength = '';
            }
        },
        removePhoto() {
            this.form.photo = "";
        },
        removeVideo() {
            this.form.url = "";
        },
    },
};
</script>
<style scoped lang='scss'>
@import "~@/styles/variables.scss";
.img{
    position: relative;
    width: 150px;
    height: 150px;
}
.imgBox {
    width: 150px;
    height: 150px;
    object-fit: contain;
    border: 1px solid $tableColor;
}
.remove {
    position: absolute;
    right: -0.5em;
    top: -0.5em;
    line-height: 1;
    font-size: 16px;
    color: #aaaaaa;
    // background-color: #ffffff;
    cursor: pointer;
    z-index: 10;
}
.defalutImg {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 150px;
    height: 150px;
    border: 1px solid $tableColor;
    border-radius: 4px;
    cursor: pointer;
}
</style>
      
        