
<template>
	<el-dialog width="60%" title="评价详情" :visible.sync="visible" :close-on-click-modal="false"
		:close-on-press-escape="false" :show-close="false" custom-class="basicDialog" append-to-body>

		<generalTable ref="mainTable" :table-data="tableData" :config="tableConfig" :total="total">

			<template slot="evaluate" slot-scope="scope">
				<el-popover placement="top-start" width="300" trigger="hover" :visible-arrow="true"
					:content="scope.data.row.evaluate">
					<div class="desc-text" slot="reference">{{ scope.data.row.evaluate }}</div>
				</el-popover>
			</template>
		</generalTable>
		<template slot="footer" class="dialog-footer">
			<el-button @click="updateVisible(false)">取 消</el-button>
		</template>
	</el-dialog>
</template>
<script>
import { getDetail } from '@/api/evaluation'
import generalTable from "@/components/Table";

export default {
	props: {
		orderNo: {
			type: String,
			required: true
		}
	},
	components: {
		generalTable,
	},
	data() {
		return {
			visible: false,
			tableData: [],
			tableConfig: {
				align: 'center', // 不必填 默认为center
				selection: 'normal', // 不必填 多选: multiple  单选: single  默认为 normal
				index: true, // 不必填 序号
				pagination: true, // 不必填 分页 默认为false
				column: [
					{
						label: '服务次数', // 必填
						prop: 'first', // 必填
						formatter: (row, column, cellValue, index) => {
							return '第' + (index + 1) +  "次"
						}
					},
					{
						label: '服务开始时间', // 必填
						prop: 'startTime' // 必填
					},
					{
						label: '服务结束时间', // 必填
						prop: 'endTime' // 必填
					},
					{
						label: '评分', // 必填
						prop: 'star', // 必填
					},
					{
						label: '评价详情', // 必填
						slot: 'evaluate' // 必填
					}
				]
			},
			paramObj: null, // 筛选项
			total: 0, // 总条数
		}
	},
	watch: {
		visible(newVal, oldVal) {
			if (newVal && this.orderNo) this.getDetail()
		}
	},
	created() { },
	mounted() {
	},
	methods: {
		getDetail() {
			this.$nextTick(() => {
				const param = this.getParam();
				getDetail(param).then(({ res, timeStamp }) => {
					this.tableData = res.records;
					this.timeStamp = timeStamp;
					this.total = res.total;
				});
			});
		},
		getParam() {
			const page = this.$refs.mainTable.getPage();
			const param = Object.assign({}, this.searchObj, page, {
				// orderNo: "1569877114604806145"
				orderNo: this.orderNo
			});
			this.paramObj = param;
			return param;
		},
		updateVisible(flag) {
			this.visible = flag
		}
	}
}
</script>
<style scoped lang='scss'>
	.desc-text{
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
</style>
