<template>
    <div>
        <generalTable ref="mainTable" :table-data="tableData" :config="tableConfig" :total="total" @updatePage="getList"
            @onClickRow="onClickRow">
        </generalTable>
        <detailDialog :orderNo="checkOrderNo" ref="detail" />
    </div>
</template>

<script>
import { getList, deleteData } from "@/api/evaluation";
import searchForm from "@/components/SearchForm";
import generalTable from "@/components/Table";
import detailDialog from "@/views/operation/evaluation/detailDialog";
export default {
    components: {
        // searchForm,
        generalTable,
        detailDialog,
    },
    props: {
        searchObj: Object
    },
    data() {
        return {
            tableData: [],
            searchOptions: [
                {
                    type: "input",
                    placeholder: "请输入...",
                    key: "name",
                    label: "评论人",
                    defaultVal: "",
                },
                {
                    type: "input",
                    placeholder: "请输入...",
                    key: "name",
                    label: "手机号",
                    defaultVal: "",
                },
            ],
            tableConfig: {
                id: "evaluation",
                align: "center", // 不必填 默认为center
                selection: "single", // 不必填 多选: multiple  单选: single  默认为 normal
                index: true, // 不必填 序号
                pagination: true, // 不必填 分页 默认为false
                column: [
                    {
                        label: "订单号", // 必填
                        prop: "orderNo", // 必填
                        // formatter: (row, column) => {
                        //     return row.archivesInfoId.name
                        // }
                    },
                    {
                        label: "订单类型", // 必填
                        prop: "type", // 必填
                        formatter: (row, column) => {
                            // 后续讲调整为按类型查询判断 1-商品订单
                            return row.type == 2 ? "服务订单" : '服务订单';
                        }
                    },
                    {
                        label: "订单详情", // 必填
                        prop: "productName", // 必填
                    },
                    {
                        label: "所属医生", // 必填
                        prop: "docName", // 必填
                        formatter: (row, column) => {
                            return row.docName ? row.docName + (row.doctorTitle ? '-' + row.doctorTitle : '') : '--';
                        }
                    },
                    {
                        label: "所属医院", // 必填
                        prop: "hospitalName", // 必填
                    },
                    {
                        label: "所属科室", // 必填
                        prop: "sectionName", // 必填
                    },
                    {
                        label: "评价人", // 必填
                        prop: "patName", // 必填
                    },
                    {
                        label: "手机号", // 必填
                        prop: "mobileNumber", // 必填
                    },
                    {
                        label: "评分", // 必填
                        prop: "starAvg", // 必填
                    },
                    {
                        label: "评价详情", // 必填
                        prop: "evaluates", // 必填
                        formatter: (row, column) => {
                            return row.totalNumber ? row.evaluates + "/" + row.totalNumber : "--" 
                        }
                    },
                ],
            },
            paramObj: null, // 筛选项
            total: 0, // 总条数 
            checkOrderNo: "", // 单条数据id 用于详情或编辑请求数据用
            timeStamp: "", //时间戳 用于修改数据或删除数据

        }
    },
    watch: {
        searchObj(newVal, oldVal) {
            console.log('searchObj=', newVal)
            if (newVal) {
                this.getList()
                //...todo
            }
        }
    },
    created() {
        this.getList();
    },
    mounted: function () { },
    methods: {
        search(data) {
            Object.assign(this.paramObj, data);
            this.$refs.mainTable.handleCurrentChange(1);
        },
        clickTab(tab, event) {
            this.tabValue = tab.index
        },
        getList() {
            this.$nextTick(() => {
                const param = this.getParam();
                getList(param).then(({ res, timeStamp }) => {
                    this.tableData = res.records;
                    this.timeStamp = timeStamp;
                    this.total = res.total;
                });
            });
        },
        toInsert() {
            this.checkId = "";
            this.$refs.insert.updateVisible(true);
        },
        toDetail(scope) {
            this.checkId = scope.data.row.id;
            this.$refs.detail.updateVisible(true);
        },
        toDel(scope) {
            const { $delMsg, $delTitle, $messageBoxConfig, $handleSuccess } =
                this.commonConfig;
            this.$confirm($delMsg, $delTitle, $messageBoxConfig).then(() => {
                const param = { id: scope.data.row.id };
                deleteData(param, this.timeStamp).then(({ res }) => {
                    this.$message({ ...$handleSuccess });
                });
            });
        },
        onClickRow(row) {
            console.log("点击行:", row);
            this.checkOrderNo = row.orderNo;
            this.$refs.detail.updateVisible(true);
        },
        getParam() {
            // const paramObj = this.$refs.mainSearch.packageData();
            const page = this.$refs.mainTable.getPage();
            const param = Object.assign({}, this.searchObj, page, { type: "2" });
            this.paramObj = param;
            return param;
        },
    },
}
</script>

<style lang="scss" scoped>
@import "~@/styles/variables.scss";
</style>