<!--
 * @Author: 358617935@qq.com 358617935@qq.com
 * @Date: 2023-05-26 09:14:28
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2023-06-07 10:08:34
 * @FilePath: /recovery_home_management_dev/src/views/operation/evaluation/index.vue
 * @Description: 评价管理页面
-->

<template>
	<el-container>
		<el-header height="auto">
			<searchForm ref="mainSearch" :searchoptions="searchOptions" @search="search" :backStatus="backStatus" />
		</el-header>
		<el-main class="basic-main">
			<el-tabs style="width: 100%" v-model="tabValue" @tab-click="clickTab">
				<el-tab-pane :lazy="true" name="2" label="服务订单">
					<!-- <generalTable ref="mainTable" :table-data="tableData" :config="tableConfig" :total="total"
						@updatePage="getList" @onClickRow="onClickRow">
					</generalTable> -->
					<serviceOrder :searchObj="paramObj" ></serviceOrder>
				</el-tab-pane>
				<el-tab-pane :lazy="true" name="1" label="商品订单">
					<!-- <generalTable ref="mainTable" :table-data="tableData" :config="tableConfig" :total="total"
						@updatePage="getList" @onClickRow="onClickRow">
					</generalTable> -->
					<productOrder :searchObj="paramObj"></productOrder>

				</el-tab-pane>
			</el-tabs>
		</el-main>
	</el-container>
</template>

<script>
import searchForm from "@/components/SearchForm";
// import detailDialog from "@/views/operation/evaluation/detailDialog";
import serviceOrder from "@/views/operation/evaluation/serviceOrder";
import productOrder from "@/views/operation/evaluation/productOrder";

export default {
	name: "Evaluation",
	components: {
		searchForm,
		// detailDialog,
		serviceOrder,
		productOrder
	},
	data() {
		return {
			tableData: [],
			searchOptions: [
				{
					type: "input",
					placeholder: "请输入...",
					key: "patientsName",
					label: "评论人",
					defaultVal: "",
				},
				{
					type: "input",
					placeholder: "请输入...",
					key: "mobileNumber",
					label: "手机号",
					defaultVal: "",
				},
			],

			checkId: "", // 单条数据id 用于详情或编辑请求数据用
			backStatus: true, //返回按钮的隐藏显示
			timeStamp: "", //时间戳 用于修改数据或删除数据
			tabValue: "2",
			paramObj: null
		};
	},
	created() {
	},
	mounted: function () { },
	methods: {
		search(data) {
			this.paramObj = data
		},
		clickTab(tab, event) {
			//this.tabValue = tab.index
		},

	},
};
</script>

<style lang="scss" scoped>
@import "~@/styles/variables.scss";
</style>
