<template>
  <div :class="['navbar', isH5 && 'navbar-h5']">
    <div v-if="isPC" class="header">
      <div class="user-info">
        <Avatar useSkeletonAnimation :url="avatar" />
        <div class="user-info-main">
          <slot name="profile"></slot>
        </div>
      </div>
    </div>
    <div class="navbar-list">
      <div
        v-for="item in navBarListForShow"
        :key="item.name"
        :class="[
          'navbar-list-item',
          currentSelectedNav === item.name && 'navbar-list-item-selected',
        ]"
        @click="switchNavBar(item.name)"
      >
        <Icon
          :file="
            (currentSelectedNav === item.name && item.selectedIcon) ||
            (isPC && item.icon) ||
            (!isPC && item.h5Icon)
          "
          :width="isH5 ? '20px' : '24px'"
          :height="isH5 ? '20px' : '24px'"
        ></Icon>
        <span
            class="num"
            v-if="isPC && unreadCount >0"
          >
            {{
              unreadCount
            }}
          </span>
        <div v-if="isH5" class="navbar-list-item-name">
          {{ TUITranslateService.t(`Home.${item.label}`) }}
        </div>
      </div>
    </div>
    <div v-if="isPC" class="footer">
      <div class="setting" v-if="false">
        <div @click="toggleSetting" @mousedown.stop>
          <Icon :file="settingPNG"></Icon>
        </div>
        <div v-if="isSettingShow" class="setting-more">
          <slot name="setting"></slot>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { ref, computed, defineEmits, watch, withDefaults, defineProps } from "../../TUIKit/adapter-vue";
import { TUIStore, StoreName, TUITranslateService } from "@tencentcloud/chat-uikit-engine";
import Icon from "../../TUIKit/components/common/Icon.vue";
import Avatar from "../../TUIKit/components/common/Avatar/index.vue";
import messageWebSVG from "../../assets/im/icon/message.svg";
import messageH5SVG from "../../assets/im/icon/message-real.svg";
import messageSelectedSVG from "../../assets/im/icon/message-selected.svg";
import commonConfig from "@/config/common";
import relationWebSVG from "../../assets/im/icon/relation.svg";
import relationH5SVG from "../../assets/im/icon/relation-real.svg";
// import relationSelectedSVG from "../assets/icon/relation-selected.svg";
import profileH5SVG from "../../assets/im/icon/profile-real.svg";
// import profileSelectedSVG from "../../assets/im/icon/profile-selected.svg";
import settingPNG from "../../assets/im/icon/setting.png";
import { IUserProfile } from "../../TUIKit/interface";
import { isPC, isH5 } from "../../TUIKit/utils/env";
export interface INavBarItem {
  icon: any;
  h5Icon: any;
  selectedIcon: any;
  name: string;
  label: string;
}
const __sfc_main = {};
__sfc_main.props = {
  currentNavBar: {
    key: "currentNavBar",
    required: false,
    type: String,
    default: () => "message"
  },
  isSettingShow: {
    key: "isSettingShow",
    required: false,
    type: Boolean,
    default: false
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const emits = __ctx.emit;
  const unreadCount = ref<number>(0);
  const currentSettingStatus = ref<boolean>(props.isSettingShow);
  const currentSelectedNav = ref(props.currentNavBar);
  const navBarListForShow = computed<Array<INavBarItem>>(() => {
    return isPC ? navBarList.filter((item: INavBarItem) => item.name !== "profile") : navBarList;
  });
  const avatar = ref<string>("");
  TUIStore.watch(StoreName.USER, {
    userProfile: (userProfileData: IUserProfile) => {
      // avatar.value = userProfileData?.avatar || "";
      // 显示客服默认头像
      avatar.value = commonConfig.$filePublicUrl + 'miniprogram/journey/ip-avator.png?imageView2/2/h/100';
    }
  });
  TUIStore.watch(StoreName.CONV, {
    conversationList: (list: Array<IConversationModel>) => {
      let unreadCounts = list.map(item => item.unreadCount);
      unreadCount.value = unreadCounts.reduce((prev, cur) => {
        return prev + cur;
      });
    }
  });
  const navBarList: Array<INavBarItem> = [{
    icon: messageWebSVG,
    h5Icon: messageH5SVG,
    selectedIcon: messageSelectedSVG,
    name: "message",
    label: "消息"
  }
  // {
  //   icon: relationWebSVG,
  //   h5Icon: relationH5SVG,
  //   selectedIcon: relationSelectedSVG,
  //   name: "relation",
  //   label: "通讯录",
  // },
  // {
  //   icon: profileH5SVG,
  //   h5Icon: profileH5SVG,
  //   selectedIcon: profileSelectedSVG,
  //   name: "profile",
  //   label: "个人中心",
  // },
  ];
  function toggleSetting() {
    currentSettingStatus.value = !currentSettingStatus.value;
    emits("toggleSettingShow", currentSettingStatus.value);
  }
  function switchNavBar(name: string) {
    currentSelectedNav.value = name;
    emits("switchNavBar", name);
  }
  watch(() => props.currentNavBar, () => {
    currentSelectedNav.value = props.currentNavBar;
  }, {
    immediate: true
  });
  watch(() => props.isSettingShow, () => {
    currentSettingStatus.value = props.isSettingShow;
  }, {
    immediate: true
  });
  return {
    TUITranslateService,
    settingPNG,
    isPC,
    isH5,
    unreadCount,
    currentSelectedNav,
    navBarListForShow,
    avatar,
    toggleSetting,
    switchNavBar
  };
};
__sfc_main.components = Object.assign({
  Avatar,
  Icon
}, __sfc_main.components);
export default __sfc_main;
</script>
<style scoped lang="scss">
.navbar {
  box-sizing: border-box;
  width: 60px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0 17px;
  background: #e8e8e9;
  user-select: none;

  .navbar-list {
    box-sizing: border-box;
    display: flex;
    flex: 1;
    flex-direction: column;
    width: 100%;

    .navbar-list-item {
      padding: 13px;
      cursor: pointer;
    }
    .navbar-list-item-selected {
      background: #ddd;
    }
  }
  .header,
  .footer {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  .header {
    .user-info {
      padding: 12px;
      position: relative;
      .avatar {
        width: 36px;
        height: 36px;
        border-radius: 5px;
      }
      &:hover {
        .user-info-main {
          display: block;
        }
      }
      .user-info-main {
        width: 100%;
        display: none;
        position: absolute;
        min-width: 165px;
        max-width: 200px;
        top: 0;
        left: 100%;
        z-index: 5;
        padding: 14px 20px;
        border-radius: 0 4px 4px 0;
        background: #fff;
        box-shadow: 0 1px 10px 0 rgba(2, 16, 43, 0.15);
      }
    }
  }
  .footer {
    .setting {
      cursor: pointer;
      padding: 12px;
      position: relative;
      flex: 1;
      .setting-more {
        background: #fff;
        box-shadow: 0 1px 10px 0 rgba(2, 16, 43, 0.15);
        min-width: 11.25rem;
        border-radius: 0 4px 4px 0;
        position: absolute;
        bottom: 10px;
        left: 100%;
        z-index: 2;
        white-space: nowrap;
      }
    }
  }

  .num {
    position: relative;
    display: inline-block;
    right: -20px;
    top: -30px;
    min-width: 10px;
    width: fit-content;
    padding: 0 2.5px;
    background-color: red;
    height: 15px;
    font-size: 10px;
    color: #FFF;
    text-align: center;
    line-height: 15px;
    border-radius: 7.5px;
  }
}
.navbar-h5 {
  padding: 0px;
  flex-direction: row;
  flex: 1;
  box-shadow: 0 0 18px 0 #f0f6ff;
  border-radius: 9px;
  overflow: hidden;
  .navbar-list {
    flex-direction: row;
    background: #ebf0f6;
    justify-content: space-around;
    .navbar-list-item {
      flex: 1;
      padding: 10px;
      .navbar-list-item-name {
        text-align: center;
        padding-top: 4px;
        font-size: 12px;
        color: #a0a3a6;
      }
    }
    .navbar-list-item-selected {
      background-color: inherit;
      .navbar-list-item-name {
        color: #006eff;
      }
    }
  }
}
</style>
