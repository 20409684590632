<template>
  <el-container>
    <el-card>
      <el-form
        ref="insertForm"
        :model="form"
        label-position="right"
        label-width="130px"
        :rules="rules"
      >
        <el-form-item label="资源名称" prop="sourceName">
          <el-input
            v-model="form.sourceName"
            placeholder="请输入..."
            clearable
            maxlength="200"
            :disabled="sourceDisable"
          />
        </el-form-item>
        <el-form-item label="资源价格" prop="originPrice">
          <el-input
            type="number"
            v-model="form.originPrice"
            placeholder="请输入..."
            @change="numberLimit(0, 9999999.99, 'originPrice')"
            :disabled="sourceDisable"
          />
        </el-form-item>
        <el-col :sm="12" :md="12" :lg="12" :xl="12">
          <el-form-item label="资源标签" prop="tag">
            <el-cascader
              :options="tagOptions"
              ref="dieaseTagRef"
              :props="{
                multiple: true,
                checkStrictly: true,
                label: 'tagName',
                value: 'tagValue',
                disabled: 'disable',
              }"
              v-model="form.tag"
              popper-class="addscheme-cascader-parent"
              maxlength="200"
              clearable
            >
            </el-cascader>
          </el-form-item>
        </el-col>
        <el-form-item
          label="资源归属类型"
          prop="sourceOwnerType"
          :style="form.sourceOwnerType == '0' ? 'flex: 0 0 100%;' : ''"
        >
          <el-radio-group v-model="form.sourceOwnerType" @change="ownerChange" :disabled="sourceDisable">
            <el-radio-button label="0">平台</el-radio-button>
            <el-radio-button label="1">机构</el-radio-button>
            <el-radio-button label="2">医生</el-radio-button>
          </el-radio-group>
        </el-form-item>
        <template v-if="form.sourceOwnerType == '1'">
          <el-form-item label="机构名称" prop="institutionIdList">
            <el-select
              v-model="form.institutionIdList"
              :filterable="true"
              :multiple-limit="10"
              multiple
              clearable
              placeholder="请选择"
              :disabled="sourceDisable"
            >
              <el-option
                v-for="op in officeTree"
                :key="op.id"
                :label="op.name"
                :value="op.id"
              />
            </el-select>
          </el-form-item>
        </template>
        <template v-if="form.sourceOwnerType == '2'">
          <el-form-item label="医生姓名" prop="doctorNameList">
            <div style="position: relative">
              <el-input
                ref="doctorNameInput"
                v-model="form.doctorNameList"
                placeholder="请输入..."
                :disabled="sourceDisable"
              />
              <div style="position: absolute; inset: 0" @click="openDocDialog"></div>
            </div>
          </el-form-item>
        </template>
        <el-form-item label="资源类型" prop="sourceKey">
          <el-radio-group v-model="form.sourceKey" @change="sourceKeyChange()" :disabled="sourceDisable">
            <el-radio-button :label="t.sourceKey" v-for="(t, index) in sourceKeyList" :key="index">{{
              t.sourceName
            }}</el-radio-button>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="资源子类型" prop="sourceSubKey">
          <el-checkbox-group v-model="form.sourceSubKey" @change="sourceSubKeyChange()" :disabled="sourceDisable">
            <el-checkbox :label="source.sourceKey" v-for="(source, index) in sourceSubKeyList" :key="index">{{
              source.sourceName
            }}</el-checkbox>
          </el-checkbox-group>
        </el-form-item>
        <el-form-item label="资源属性" prop="sourceInfo">
          <template v-if="form.sourceSubKey[0] == 'online_solutions'">
            <!-- 在线方案 -->
            训练演示 &nbsp;
            <el-select
              v-model="sourceInfoId"
              :filterable="true"
              clearable
              placeholder="请选择"
              multiple
              style="width: 50%"
              :disabled="sourceDisable"
              @change="videoChange"
            >
              <el-option
                v-for="op in sourceList"
                :key="op.id"
                :label="op.name"
                :value="op.id"
              />
            </el-select>
            <!-- <br>
            训练时长 &nbsp; <el-input v-model="videoLength" placeholder="请输入..." style="width: 50%;" /> -->
          </template>
          <template v-if="form.sourceSubKey[0] == 'online_solutions_disassemble'">
            <!-- 在线方案 -->
            训练演示 &nbsp;
            <el-select
              v-model="sourceInfoId"
              :filterable="true"
              clearable
              placeholder="请选择"
              style="width: 50%"
              :disabled="sourceDisable"
              @change="videoChange"
            >
              <el-option
                v-for="op in sourceList"
                :key="op.id"
                :label="op.name"
                :value="op.id"
              />
            </el-select>
            <!-- <br>
            训练时长 &nbsp; <el-input v-model="videoLength" placeholder="请输入..." style="width: 50%;" /> -->
          </template>
          <template v-else-if="form.sourceSubKey[0] == 'offline_rehabilitation'">
            <!-- 上门康复 -->
            结论 &nbsp;
            <el-switch
              v-model="form.sourceInfo.isFill"
              :inactive-value="false"
              :active-value="true"
              inactive-text="不填写"
              active-text="填写"
              :disabled="sourceDisable"
            />
          </template>
          <template v-else-if="form.sourceSubKey[0] == 'online_consultation'">
            <!-- 在线咨询 -->
            服务次数 &nbsp;
            <el-input
              type="number"
              v-model="form.sourceInfo.times"
              placeholder="请输入..."
              @change="numberLimit(1, 99, 'sourceInfo', 'times')"
              style="width: 50%"
              :disabled="sourceDisable"
            />
            &nbsp;次
          </template>
          <template v-else-if="!form.sourceSubKey[0]">
            <span class="delBtn"> 请选择“资源子类型” </span>
          </template>
          <template v-else>
            <!-- 在线评估：评估项 -->
            <!-- 上门评估：评估量表 -->
            <!-- 康复课程：课程选择 -->
            <!-- 甄选商品：商品选择 -->
            {{ sourceLabelMap[form.sourceSubKey[0]] }}
            &nbsp;
            <el-select
              v-model="sourceInfoId"
              :filterable="true"
              :multiple-limit="10"
              multiple
              clearable
              placeholder="请选择"
              style="width: 50%"
              :disabled="sourceDisable"
              v-if="ifForceUpdate && sourceLabelMap[form.sourceSubKey[0]]"
            >
              <el-option
                v-for="op in sourceList"
                :key="op.id"
                :label="op.name"
                :value="op.id"
              />
            </el-select>
          </template>
        </el-form-item>
        <el-form-item label="资源描述" prop="sourceDesc">
          <el-input
            type="textarea"
            v-model="form.sourceDesc"
            placeholder="请输入..."
            clearable
            :disabled="sourceDisable"
            maxlength="200"
          />
        </el-form-item>
        <el-form-item label="资源状态" prop="state">
          <el-switch
            v-model="form.state"
            inactive-value="0"
            active-value="1"
            inactive-text="禁用"
            active-text="启用"
            v-if="form.id"
          />
          <el-radio v-else value="1" label="1">启用</el-radio>
        </el-form-item>
        <el-form-item label="医生固定分润" prop="state" v-if="form.sourceSubKey[0] == 'online_solutions' || form.sourceSubKey[0] == 'online_solutions_disassemble'">
          <el-row>
            <el-col :span="12"><div style="position: relative">
            <el-input
              ref="doctorShareProfitInput"
              v-model="form.doctorName"
              clearable
              readonly
              placeholder="请选择医生"
              :disabled="sourceDisable"
            />
            <div style="position: absolute; inset: 0" @click="openDocsSingleDialog">
              <i class="el-icon-delete delBtn clearBtn" @click.stop="handleDeleteProfitDoctor()" v-if="form.doctorName"></i>
            </div>
          </div></el-col>
          <el-col :span="12">
            <el-input-number
              v-model="form.profitScale"
              @change="handleChange"
              size="mini"
              :precision="0"
              :min="0"
              :step="10"
              :max="99"
              label=""
              :disabled="sourceDisable"
            ></el-input-number
            >%
          </el-col>
          </el-row>
        </el-form-item>
      </el-form>
      <div
        style="
          display: flex;
          justify-content: space-around;
          width: 400px;
          margin: 2em 0 0 56px;
        "
      >
        <el-button type="primary" @click="submit()">提交</el-button>
        <el-button @click="$router.back(0)">返回</el-button>
      </div>
    </el-card>
    <doctorListDialog
      ref="doctorListDialog"
      :defaultCheckIds="form.doctorIdList"
      selectMode="multiple"
      @submit="setDoctorList"
    />
    <doctorListDialog
      ref="doctorListProfitDialog"
      :defaultCheckIds="profitDoctorId"
      selectMode="single"
      @submit="setDoctorProfitList"
    />
  </el-container>
</template>
<script>
import doctorListDialog from "../project/doctorListDialog";
import request from "@/utils/request";
import { getDoctorNames } from "@/api/doctorManagement";
import { getSchemeTagsOptions } from "@/api/project";
export default {
  name: "source-add",
  components: {
    doctorListDialog,
  },
  data() {
    return {
      ifForceUpdate: true,
      detailId: "",
      tagOptions: [],
      sourceDisable:false,
      form: {
        sourceOwnerType: "0",
        sourceSubKey: [],
        profitScale: 0,
        sourceInfo: { id: "", isFill: true },
        doctorNameList: "",
        institutionIdList: [],
      },
      profitDoctorId:[],
      sourceInfoId: [],
      rules: {
        sourceName: [
          {
            required: true,
            message: "请输入资源名称",
            trigger: "blur",
          },
        ],
        originPrice: [
          {
            required: true,
            message: "请输入资源价格",
            trigger: "blur",
          },
        ],
        institutionIdList: [
          {
            required: true,
            message: "请输入机构名称",
            trigger: "change",
          },
        ],
        doctorNameList: [
          {
            required: true,
            message: "请输入医生姓名",
            trigger: "blur",
          },
        ],
        tag: [
          {
            required: true,
            message: "请选择资源标签",
            trigger: "blur",
          },
        ],
        sourceKey: [
          {
            required: true,
            message: "请输入资源类型",
            trigger: "check",
          },
        ],
        sourceSubKey: [
          {
            required: true,
            message: "请输入资源子类型",
            trigger: "change",
          },
        ],
      },
      officeTree: [],
      sourceKeyList: [],
      sourceSubKeyList: [],
      sourceList: [],
      sourceLabelMap: {
        online_assessment: "评估项",
        offline_assessment: "评估量表",
        rehabilitation_courses: "课程选择",
        select_goods: "商品选择",
      },
      videoLength: "",
      initDoctorList: [],
    };
  },
  computed: {},
  created() {
    const { id } = this.$route.query;
    this.init(id);
    this.getSchemeTagsOptionsList();

    // 机构树
    request({
      url: "admin/kfHospital/downList",
      method: "GET",
      showLoading: true,
    }).then(({ res }) => {
      this.officeTree = res.records;
    });
    // 资源类型树
    request({
      url: "admin/kfSourceType/treeList",
      method: "GET",
      showLoading: true,
    }).then(({ res }) => {
      this.sourceKeyList = res;
      // 用于新增
      if (!this.form.sourceKey) {
        this.form.sourceKey = this.sourceKeyList[0].sourceKey;
      }
      this.sourceKeyChange(false);
    });
  },
  methods: {
    init(id) {
      this.detailId = id;
      if (id) {
        request({
          url: "admin/kfSource/info",
          method: "GET",
          params: { id },
          showLoading: true,
        }).then(({ res, timeStamp }) => {
          res.doctorNameList = "";
          this.form = JSON.parse(JSON.stringify(res));
          this.form.originPrice = this.form.originPrice / 100;
          this.form.sourceSubKey = [this.form.sourceSubKey];
          this.form.doctorIdList = res.doctorIdList.map((doc) => doc.id);
          this.form.doctorNameList = res.doctorIdList.map(doc => doc.name).join(',');
          this.profitDoctorId= res.profitDoctorId ? [res.profitDoctorId] : [];
          this.initDoctorList = res.doctorIdList;
          this.form.institutionIdList = res.institutionIdList.map((r) => r.id);
          this.sourceDisable = res.sourceDisable || false;
          if (this.form.sourceSubKey == "online_solutions") {
            // 在线方案
            //this.sourceInfoId = res.sourceInfo[0].id;
            this.sourceInfoId = res.sourceInfo.map((r) => r.id);
            this.videoLength = res.sourceInfo[0].videoLength;
            // setTimeout(() => {
            //     this.videoChange();
            // }, 1000)
          }  if (this.form.sourceSubKey == "online_solutions_disassemble") {
            this.sourceInfoId = res.sourceInfo[0].id;
          } else if (this.sourceLabelMap[this.form.sourceSubKey]) {
            this.sourceInfoId = res.sourceInfo.map((r) => r.id);
          } else {
            this.form.sourceInfo = res.sourceInfo[0];
          }
          console.table('this.form',this.form);
          this.sourceKeyChange(false);
          this.sourceSubKeyChange(false);
          this.$nextTick(() => {
            this.$refs.insertForm.clearValidate();
          });
        });
      } else {
        // this.form.sourceKey = this.sourceKeyList[0] ? this.sourceKeyList[0].sourceKey: '';
      }
    },
    handleDeleteProfitDoctor(){
       if (this.sourceDisable) return;
       this.form.doctorName="";
       this.profitDoctorId = [];
    },
    //获取方案标签
    getSchemeTagsOptionsList() {
      getSchemeTagsOptions({ tagOwnership: "scheme" }).then(({ res }) => {
        this.tagOptions = res;
      });
    },
    handleChange(value) {
      this.form.shareInProportion = value;
    },
    ownerChange(e) {
      if (e === "2") {
        this.$refs.insertForm.clearValidate("institutionIdList");
        this.$refs.insertForm.clearValidate("doctorNameList");
      } else if (e === "1") {
        this.$refs.insertForm.clearValidate("institutionIdList");
        this.$refs.insertForm.clearValidate("doctorNameList");
      }
    },
    submit() {
      let _this = this;
      this.$refs.insertForm.validate((valid) => {
        if (valid) {
          // console.log('sourceInfoId ------------> ', this.sourceInfoId);
          if (
            this.form.sourceSubKey == "online_solutions" &&
            this.sourceInfoId.length === 0
          ) {
            this.$message.error("请选择训练演示视频");
            return;
          }
          if (
            this.sourceLabelMap[this.form.sourceSubKey] &&
            this.sourceInfoId.length == 0
          ) {
            this.$message.error("请选择" + this.sourceLabelMap[this.form.sourceSubKey]);
            return;
          }
          if (this.form.sourceSubKey[0] == 'online_solutions' || this.form.sourceSubKey[0] == 'online_solutions_disassemble') {
            // 在线方案类型判断
            if (this.profitDoctorId.length >0 && !this.form.profitScale) {
              this.$message.error("请输入固定分润比例");
              return;
            }
            if (this.profitDoctorId.length == 0 && this.form.profitScale) {
              this.$message.error("请选择固定分润医生");
              return;
            }
            // TODO 固定分润医生是单选，因为generalTable子组件接收的是数组，此处转换为字符串
            if (Array.isArray(this.profitDoctorId)) {
              this.form.profitDoctorId = this.profitDoctorId.join('');
            }
          } else {
            // 非在线方案类型时，清空分润医生、分润比例
             this.form.profitScale = 0;
             this.form.profitDoctorId = '';
          }
          const data = JSON.parse(JSON.stringify(this.form));
          data.originPrice = parseInt(this.form.originPrice * 100);
          data.sourceInfo.times = parseInt(data.sourceInfo.times);
          data.sourceSubKey = data.sourceSubKey[0];
          // data.doctorIdList = this.form.doctorIdList.join(',');
          delete data.doctorNameList;
          if (this.sourceInfoId.length == 0) {
            // 说明是："上门康复：结论" || "在线咨询：服务次数"
            delete data.sourceInfo.id;
            data.sourceInfo = [data.sourceInfo];
          } else if (typeof this.sourceInfoId == "string") {
            data.sourceInfo = this.sourceList.filter((r) => this.sourceInfoId == r.id);
          } else {
            const sourceInfo = this.sourceList.filter((r) =>
              this.sourceInfoId.includes(r.id)
            );
            data.sourceInfo = sourceInfo;
          }

          if (this.videoLength) {
            data.sourceInfo[0].videoLength = this.videoLength;
          }

          request({
            url: "admin/kfSource/" + (!this.detailId ? "add" : "update"),
            method: "POST",
            data,
            showLoading: true,
          }).then(({ res, timeStamp }) => {
            _this.$router.back(0);
          });
        }
      });
    },
    // 资源父类型 改变
    sourceKeyChange(reset = true) {
      console.log('sourceKeyChange -----------> ', this.sourceKeyList);
      if (reset === true) {
        this.form.sourceSubKey = [];
        this.form.sourceInfo = { id: [], isFill: true };
        this.sourceList = [];
      }
      if (this.form.sourceKey) {
        const sourceRow = this.sourceKeyList.find(
          (r) => r.sourceKey == this.form.sourceKey
        );
        if (sourceRow) {
          this.sourceSubKeyList = sourceRow.children;
          return;
        }
      }
      this.sourceSubKeyList = [];
    },
    // 资源子类型 改变 获取资源 下拉
    sourceSubKeyChange(reset = true) {
      this.ifForceUpdate = false;
      this.form.sourceSubKey = this.form.sourceSubKey.slice(-1);
      if (reset === true) {
        this.form.sourceInfo = { id: [], isFill: true };
        this.sourceInfoId = [];
        if (this.form.sourceSubKey[0] == "online_solutions") {
          this.sourceInfoId = ""; // 在线方案：训练演示 是单选
        }
      }
      this.sourceList = [];
      if (this.form.sourceSubKey[0] === undefined) {
        return;
      }
      request({
        url: "admin/kfSource/getDownList",
        params: {
          sourceSubKey: this.form.sourceSubKey[0],
        },
        method: "GET",
        showLoading: true,
      }).then(({ res }) => {
        console.log("sourceList ---------> ", res);
        this.sourceList = res;
        this.ifForceUpdate = true;
        // this.$forceUpdate(); // 不强刷 下拉框有残留
      });
    },
    videoChange() {
      if (this.form.sourceInfo && this.sourceInfoId.lengh > 0) {
        this.videoLength = this.sourceList.find(
          (r) => r.id == this.sourceInfoId
        ).videoLength;
        return;
      }
      this.videoLength = "";
    },
    openDocDialog() {
      if (this.sourceDisable) return;
      this.$refs.doctorListDialog.open();
    },
    openDocsSingleDialog() {
      if (this.sourceDisable) return;
      this.$refs.doctorListProfitDialog.open();
    },
    setDoctorList(rows) {
      let namesStr = "";
      const params = {
        ids: rows,
      };
      getDoctorNames(params).then(({ res }) => {
        console.log("getDoctorNames--", res);
        namesStr = res.map((r) => r.name).join(",");
        console.log("namesStr--", namesStr);
        this.form.doctorIdList = rows;
        // this.form.doctorNameList = rows.map( r => r.name ).join(',');
        this.$set(this.form, "doctorNameList", namesStr);
        this.$refs.insertForm.validateField("doctorNameList");
      });
    },
    setDoctorProfitList(rows) {
      let namesStr = "";
      const params = {
        ids: rows,
      };
      getDoctorNames(params).then(({ res }) => {
        namesStr = res.map((r) => r.name).join(",");
        console.log("namesStr--", namesStr);
        this.profitDoctorId = [rows.join("")];
        console.log("profitDoctorId--", this.profitDoctorId);
        this.$set(this.form, "doctorName", namesStr);
      });
    },
    numberLimit(min, max, key, key2) {
      if (key2) {
        if (this.form[key][key2] > max) {
          this.form[key][key2] = max;
        } else if (this.form[key][key2] < min) {
          this.form[key][key2] = min;
        }
      } else {
        if (this.form[key] > max) {
          this.form[key] = max;
        } else if (this.form[key] < min) {
          this.form[key] = min;
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
// :deep {
  .el-form {
    display: flex;
    flex-wrap: wrap;
  }

  .el-form-item {
    flex: 0 0 70%;
  }

  .el-form-item__content {
    width: 70%;
  }
  /* 资源属性 前面的 * */
  label[for="sourceInfo"]::before {
    content: "*";
    color: #e44d42;
    margin-right: 4px;
  }
  .el-card {
    overflow: scroll;
  }
// }

.clearBtn{
  padding: 8px;
  position: absolute;
  right: 0px;
  top: 0px;
  cursor: pointer;
}
.finishSubmit {
  position: fixed;
  right: 5%;
  bottom: 10%;
  padding: 0;
  width: 50px;
  height: 50px;
  line-height: 50px;
  opacity: 0.5;
  transition: all 0.2s;
}

.finishSubmit:hover {
  opacity: 1;
}
</style>
<style lang="scss">
.addscheme-cascader-parent {
  .el-cascader-panel .el-scrollbar:first-child .el-checkbox {
    display: none;
  }
  .el-cascader-menu__wrap {
    height: 300px;
  }
}
</style>
